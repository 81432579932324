import { createTheme } from '@mui/material/styles'

import { getThemeInfo, isSmava } from '../utils/getTenantInfo.utils'

declare module '@mui/material/styles' {
  interface Theme {
    name: SupportedTheme
  }

  interface ThemeOptions {
    name?: SupportedTheme
  }
}

export enum SupportedTheme {
  Default,
  Mobilede,
  Embedded,
}

const { palette } = getThemeInfo()

const theme = createTheme({
  name: SupportedTheme.Default,
  palette,
  typography: {
    fontFamily: [
      isSmava ? 'Roboto' : 'Lato',
      '-apple-system',
      'BlinkMacSystemFont',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
})

theme.components = {
  ...theme.components,
  MuiTypography: {
    styleOverrides: {
      gutterBottom: {
        marginBottom: theme.spacing(3),
      },
      h2: {
        fontWeight: 400,
      },
      h3: {
        fontWeight: 500,
        fontSize: '1.75rem',
      },
      h4: {
        fontWeight: 700,
        fontSize: '1.25rem',
      },
      h5: {
        fontSize: '1rem',
        fontWeight: 700,
      },
    },
  },
  MuiFormControl: {
    styleOverrides: {
      root: {
        margin: '7px 0 24px 0',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        html: {
          touchAction: 'manipulation',
        },
        body: {
          backgroundColor: '#fff',
          fontSize: '0.875rem',
        },
        a: {
          color: 'rgb(22, 82, 151)',
        },
      },
    },
  },
} as typeof theme.components

export default theme
