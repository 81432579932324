export const isSmava =
  process.env.NEXT_PUBLIC_TENANT_UUID === 'f9343d8a-4bd3-48b8-a504-e99f102f463c'

export const getLogo = () => {
  return isSmava
    ? { path: 'https://cdn.smava.de/assets/smava_logo.svg', alt: 'smava.de' }
    : {
        path: 'https://cdn.finanzcheck.de/assets/finanzcheck_logo.svg',
        alt: 'FINANZCHECK.de',
      }
}

export const getPhoneNumber = () => {
  return isSmava
    ? { tel: '+4908000009804', display: '0800 000 98 04' }
    : { tel: '+490800433887766', display: '0800 433 88 77 66' }
}

export const getCopyrightText = () => {
  return isSmava
    ? ['smava.de', 'Palisadenstraße 90', '10243 Berlin']
    : ['FINANZCHECK.de', 'Admiralitätstraße 60', '20459 Hamburg']
}

const palette = {
  ffg: {
    primary: { main: '#165297', dark: '#124279', light: '#E8EEF5' },
    secondary: { light: '#FCE9CD', main: '#F09103', dark: '#C07402' },
    error: { main: '#C50E07' },
    success: { main: '#339E33', dark: '#236E23', light: '#D9ECDB' },
    text: { primary: '#4a5a5a', secondary: '#2D3748' },
  },
  smava: {
    primary: { main: '#39A949', dark: '#1B5426', light: '#EDFAEB' },
    secondary: { light: '#BFBFBF', main: '#979797', dark: '#707070' },
    error: { main: '#AE2E2E' },
    success: { main: '#39A949', dark: '#1B5426', light: '#F6FDF5' },
    text: { primary: '#323232', secondary: '#5B5B5B' },
  },
}

export const getThemeInfo = () => {
  return isSmava ? { palette: palette.smava } : { palette: palette.ffg }
}

export const getTrustCardDetails = () => {
  return {
    ekomi: {
      image: `${process.env.NEXT_PUBLIC_TEAL_CDN_URL}/assets/ekomi_badge.svg`,
      ratings: 4.9,
    },
    tuev: {
      url: `https://www.tuev-saar.de/zertifikat/${
        isSmava ? 'tk45087' : 'tk45089'
      }/`,
      image: `${process.env.NEXT_PUBLIC_TEAL_CDN_URL}/assets/${
        isSmava ? 'tuev_horizontal_45087.svg' : 'tuev_horizontal_45089.svg'
      }`,
    },
    guenstigerGehtNicht: {
      image: `${process.env.NEXT_PUBLIC_TEAL_CDN_URL}/assets/gunster_geht_nicht.svg`,
    },
    schufaNeutral: {
      url: 'https://www.finanzcheck.de/faq/schufaneutral/',
      image: `${process.env.NEXT_PUBLIC_TEAL_CDN_URL}/assets/schufa_neutral.svg`,
    },
  }
}

export const getDacFormConfig = () =>
  isSmava ? 'ddf_smava_marketing' : 'ddf_marketing'
