import { ParsedUrlQuery } from 'querystring'

const RESUME_KEY = '#!resume='

export const getResumeTokenFromPath = (path: string): string | undefined => {
  const startIndex = path.indexOf(RESUME_KEY)

  if (startIndex < 0) {
    return undefined
  }

  return path.substring(startIndex + RESUME_KEY.length, path.length)
}

export const getQueryParamsFromPath = (path: string): ParsedUrlQuery => {
  const urlParams = getUrlParamsWithoutFragment(path)
  const queryParams: ParsedUrlQuery = {}
  urlParams.forEach((value, key) => {
    queryParams[key] = value
  })

  return queryParams
}

const getUrlParamsWithoutFragment = (path: string): URLSearchParams => {
  const hashPosition = path.indexOf('#')
  const endPosition = hashPosition > 0 ? hashPosition : path.length
  const startPosition = path.indexOf('?') || 0

  return new URLSearchParams(path.substring(startPosition, endPosition))
}
