import { NextRouter } from 'next/router'
import { ParsedQuery } from 'query-string'
import { ParsedUrlQuery } from 'querystring'

import theme, { SupportedTheme } from '../../theme'
import { isSmava } from '../getTenantInfo.utils'
import { PURPOSE, DEFAULT_SELECTION_VALUES } from '../loanSelector.utils'

export const mapQueryParamsToTeal = (query: ParsedUrlQuery): any => {
  const {
    category,
    duration,
    purpose,
    term,
    vehiclePrice,
    initialPayment,
    amount,
  } = query || {}

  const vehiclePriceAsNumber = (vehiclePrice as unknown as number) || 0
  const initialPaymentAsNumber = (initialPayment as unknown as number) || 0
  const amountRequested =
    vehiclePrice &&
    ((purpose as string)?.toUpperCase() === PURPOSE.CAR.toString() ||
      (category === '887' && !purpose))
      ? vehiclePriceAsNumber - initialPaymentAsNumber
      : amount
  const defaultAmount = 30000
  const loanAmount = amountRequested ?? defaultAmount
  return {
    ...query,
    amount: loanAmount,
    purpose:
      category && !purpose
        ? mapGreenCategoryToTeal[category as string]
        : withInvalidCases(
            (purpose as unknown as string)?.toUpperCase() as PURPOSE,
          ),
    term: duration && !term ? duration : term,
    initialPayment: initialPaymentAsNumber,
    vehiclePrice: vehiclePrice ? vehiclePriceAsNumber : loanAmount,
  }
}

const withInvalidCases = (purpose: PURPOSE): string =>
  Object.values(PURPOSE).includes(purpose) ? purpose : PURPOSE.FREE_USE

const mapGreenCategoryToTeal: Record<string, string> = {
  '886': 'RENOVATION',
  '887': 'PRE_OWNED_CAR',
  '888': 'OTHER',
  '8992946': 'REFINANCING',
}

export const getAdvertisementId = (
  query: ParsedUrlQuery | ParsedQuery<string>,
): string => {
  let advertisementId =
    query?.advertisementId ||
    query?.advertisement ||
    query?.refId ||
    process.env.NEXT_PUBLIC_DEFAULT_ADVERTISEMENT_ID ||
    ''

  if (isSmava) {
    if (theme.name === SupportedTheme.Mobilede) {
      if (!query.paapi) {
        // mobilede (redirect)
        advertisementId =
          process.env.NEXT_PUBLIC_DEFAULT_MOBILEDE_ADVERTISEMENT_ID || ''
      } else {
        // mobilede (paapi)
        advertisementId =
          process.env.NEXT_PUBLIC_PAAPI_MOBILEDE_ADVERTISEMENT_ID || ''
      }
    }
  }

  return advertisementId as string
}

export const getRefId = (
  query: ParsedUrlQuery | ParsedQuery<string>,
): string => {
  const { ref, refId, affiliateId } = query

  const legacyRefId = ref || affiliateId

  if (refId) {
    return refId as string
  } else if (legacyRefId && (legacyRefId as string).match(/^[a-f0-9]{8}$/i)) {
    return `affx${legacyRefId}`
  } else if (legacyRefId && (legacyRefId as string).length === 12) {
    return legacyRefId as string
  } else {
    return getAdvertisementId(query)
  }
}

export const isValidPosInteger = (
  value: string | string[] | undefined | number | null,
) => {
  const parsedValue = parseToInt(value)
  if (parsedValue >= 0) return true

  return false
}

export const parseToInt = (
  value: string | string[] | undefined | number | null,
) => {
  const stringValue = String(value)
  const parsedValue = parseInt(stringValue)
  return parsedValue
}

export const getLoanSelectionFromQueryParams = (
  queryParams: ParsedUrlQuery,
) => {
  const { amount, vehiclePrice, initialPayment, purpose, term } =
    mapQueryParamsToTeal(queryParams)

  const parsedQueryParams = {
    amount: isValidPosInteger(amount)
      ? parseToInt(amount)
      : DEFAULT_SELECTION_VALUES.amount,
    vehiclePrice: isValidPosInteger(vehiclePrice || amount)
      ? parseToInt(vehiclePrice || amount)
      : DEFAULT_SELECTION_VALUES.vehiclePrice,
    initialPayment: isValidPosInteger(initialPayment)
      ? parseToInt(initialPayment)
      : DEFAULT_SELECTION_VALUES.initialPayment,
    purpose: purpose?.toUpperCase() || DEFAULT_SELECTION_VALUES.purpose,
    term: isValidPosInteger(term)
      ? parseToInt(term)
      : DEFAULT_SELECTION_VALUES.term,
  }

  return parsedQueryParams
}

export const getRegRoutePath = (prefix: string) =>
  /coop\/(antrag|embed)$/.test(prefix) ? `${prefix}/regroute` : ''

export const routerPushWithResumeToken = (
  router: NextRouter,
  options: Record<string, unknown>,
  ...args: any[]
) => {
  const { query } = options
  const resume = (query as ParsedUrlQuery).resume
  delete (query as ParsedUrlQuery).resume

  router.push(
    {
      ...options,
      ...(resume && { hash: `!resume=${resume}` }),
    },
    ...args,
  )
}
