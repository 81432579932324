import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'

import {
  getResumeTokenFromPath,
  getQueryParamsFromPath,
} from '../utils/getFromPath.utils'

const useUrlParams = (): ParsedUrlQuery => {
  const { asPath } = useRouter()
  const path = asPath.substring(1)

  const resume = getResumeTokenFromPath(path)
  const queryParams = getQueryParamsFromPath(path)

  return {
    ...(resume && { resume }),
    ...queryParams,
  }
}

export default useUrlParams
