import Script from 'next/script'
import initOneSegment, {
  cookieBannerSnippet,
  CookieBannerTypes,
} from '@finanzcheck/one-segment'
import { InitOptions } from '@finanzcheck/one-segment/dist/init'
import theme, { SupportedTheme } from '../../theme'
import { getPageTrackingData } from '../../utils/segment'

const getCookieBannerType = () => {
  const { name: themeName } = theme

  switch (themeName) {
    case SupportedTheme.Mobilede:
      return CookieBannerTypes.MOBLE_DE
    case SupportedTheme.Embedded:
      return CookieBannerTypes.AFFILIATES
    default:
      return CookieBannerTypes.MAIN
  }
}

export const OneSegmentHead = () => (
  <script
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{
      __html: cookieBannerSnippet(getCookieBannerType()),
    }}
  />
)

const initOneSegmentOptions: InitOptions = {
  debug: process.env.NEXT_PUBLIC_ENV === 'development',
  initialPageTrack: false,
  teamName: 'anchor',
  sessionCookieDomain: process.env.NEXT_PUBLIC_COOKIE_BASE_URL,
}
if (process.env.NEXT_PUBLIC_SEGMENT_HOST) {
  initOneSegmentOptions.segmentHost = process.env.NEXT_PUBLIC_SEGMENT_HOST
}

export const OneSegmentBody = () => (
  <Script
    strategy='lazyOnload'
    id='init-one-segment'
    src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
    data-domain-script={process.env.NEXT_PUBLIC_ONE_TRUST_SCRIPT_ID}
    onLoad={() => {
      initOneSegment(
        process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        initOneSegmentOptions,
      )
      // eslint-disable-next-line no-undef
      window?.analytics?.page(getPageTrackingData())

      window?.addEventListener('message', (event) => {
        if (
          event?.data === 'CONSENT_CHANGED' &&
          event?.origin?.includes('smava.de')
        ) {
          window.Optanon.Close()
        }
      })
    }}
  />
)
