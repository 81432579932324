/*
  The integration of GTM tracking pixel is only for our special 
  and very important partner PostBank - [ANCH-1459]
  AdvertisementId: rD5wRHeYp49e
*/

import { useRouter } from 'next/router'

const allowedAdvertisements = {
  // PostBank
  rD5wRHeYp49e: {
    gtmId: 'AW-920776111',
  },
  // Testing purpose
  p5QGTxy3Ql3p: {
    gtmId: '123456789000',
  },
}

const isAllowed = (advertisementId: string) =>
  Object.keys(allowedAdvertisements).includes(advertisementId as string)

const gtmId = (advertisementId: string) =>
  allowedAdvertisements[advertisementId as keyof typeof allowedAdvertisements]
    .gtmId

export const GTM = () => {
  const router = useRouter()
  const advertisementId = router?.query?.advertisementId as string

  return isAllowed(advertisementId) ? (
    <>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${gtmId(
          advertisementId,
        )}`}
      />
      <script
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];

            function gtag() { dataLayer.push(arguments); }
          
            gtag('js', new Date());
            gtag('config', '${gtmId(advertisementId)}');
          `,
        }}
      />
    </>
  ) : null
}
