import { getCookie, setCookie } from './cookies.utils'

export const SESSION_COOKIE_NAME = 'FCID'
const DEFAULT_CHARSET = 'abcdef0123456789'

// Why don't we just use browser.crypto with nodejs on server side?
// The browserify.crpto polyfill is added to the bundle with a size of 260kb

/**
 * Creates a random string using a fallback for crypto
 */
export const getFallbackRandomString = (
  length = 26,
  charSet = DEFAULT_CHARSET,
) => {
  let string = ''
  for (let i = 0; i < length; i += 1) {
    const randomPoz = Math.floor(Math.random() * charSet.length)
    string += charSet.substring(randomPoz, randomPoz + 1)
  }
  return string
}

/**
 * Creates a random string using the browser's crypto module and falls back to
 * an altenative if it fails or is done on server side
 */
export const getCryptoRandomString = (
  length = 26,
  charSet = DEFAULT_CHARSET,
) => {
  const arr = new Uint8Array(length)
  const crypto = window?.crypto

  if (!crypto) return getFallbackRandomString(length)

  crypto.getRandomValues(arr)
  let string = ''
  for (let i = 0; i < length; i += 1) {
    string += charSet[arr[i] % charSet.length]
  }

  return string
}

/**
 * Returns a new FCID randomly generated
 * Double check if there was anything hoing wrong which triggers a
 * fallback to the fallback random string
 */
export function generateSessionId() {
  const length = 26
  try {
    return getCryptoRandomString(length)
  } catch (e) {
    return getFallbackRandomString(length)
  }
}

/**
 * Generates a session ID and writes it in the FCID cookie on client side
 */
export const getSessionId = () => {
  const fcidCookieValue = getCookie(SESSION_COOKIE_NAME)
  if (fcidCookieValue) {
    return fcidCookieValue
  }
  const newFcidValue = generateSessionId()
  setCookie(SESSION_COOKIE_NAME, newFcidValue, {
    sameSite: 'none',
    secure: true,
  })
  return newFcidValue
}

/**
 * Compatibility function, also gets/sets the session id in the FCID cookie
 */
export const setSessionId = () => {
  getSessionId()
}
