import queryString from 'query-string'
import { getGenericTrackingData } from '@finanzcheck/one-segment'

import { getAdvertisementId } from '../url'

export enum TrackingEvents {
  Tracking = 'tracking',
  PageVisit = 'trackPageVisit',
  Identify = 'callSegmentIdentify',
}

const getVirtualPageTrackingData = (data: any) => {
  const queryParams = queryString.parse(window?.document?.location?.search)
  const { subId, refId, paapi } = queryParams

  return {
    configId: getAdvertisementId(queryParams),
    ...data,
    ...(subId && { subId }),
    ...(refId && { refId }),
    ...(paapi && { paapi }),
  }
}

/* eslint consistent-return: "warn" */
export const virtualPageTrackingHandler = ({ data }: any) => {
  if (typeof data === 'string') {
    try {
      const dataObject = JSON.parse(data)

      if (dataObject.eventName === TrackingEvents.Tracking) {
        const { event, context, ...rest } = dataObject.data
        window?.analytics?.track(
          event,
          getVirtualPageTrackingData(rest),
          context,
        )
      } else if (dataObject.eventName === TrackingEvents.PageVisit) {
        window?.analytics?.page(getVirtualPageTrackingData(dataObject.data))
      } else if (dataObject.eventName === TrackingEvents.Identify) {
        window?.analytics?.identify(dataObject.data)
      }
    } catch (ex) {}
  }
}

export const getPageTrackingData = () => {
  const {
    subId,
    refId,
    amount,
    purpose,
    term,
    data1,
    data2,
    fbclid,
    fcId,
    gclid,
    initialPayment,
    placementId,
    ref,
    subaffiliateid,
    utm_campaign,
    utm_content,
    utm_medium,
    utm_source,
    utm_term,
  } = queryString.parse(window?.document?.location?.search)

  return {
    ...(amount && { amount }),
    ...(purpose && { purpose }),
    ...(term && { term }),
    ...(initialPayment && { initialPayment }),
    ...(subId && { subId }),
    ...(refId && { refId }),
    ...(data1 && { data1 }),
    ...(data2 && { data2 }),
    ...(fbclid && { fbclid }),
    ...(fcId && { fcId }),
    ...(gclid && { gclid }),
    ...(placementId && { placementId }),
    ...(ref && { ref }),
    ...(subaffiliateid && { subaffiliateid }),
    ...(utm_campaign && { utm_campaign }),
    ...(utm_content && { utm_content }),
    ...(utm_medium && { utm_medium }),
    ...(utm_source && { utm_source }),
    ...(utm_term && { utm_term }),
    ...getGenericTrackingData(),
  }
}

export const trackCTAClick = (data: any) => {
  const { amount, purpose, term, vehiclePrice, initialPayment, pageUrl } = data

  window?.analytics?.track('form_submission-stage_loanselector_form', {
    category: 'form_submission',
    label: 'submit',
    formName: 'stage_loanselector_form',
    formAction: 'submit',
    formLocation: pageUrl,
    ...(amount && { amount }),
    ...(purpose && { purpose }),
    ...(term && { term }),
    ...(initialPayment && { initialPayment }),
    ...(vehiclePrice && { vehiclePrice: vehiclePrice.toString() }),
    ...getGenericTrackingData(),
  })
}

export const trackOverlayButtonClick = () => {
  const currentPageLocation = window?.document?.location
  const { amount, purpose, term, vehiclePrice, initialPayment } =
    queryString.parse(currentPageLocation?.search)

  window?.analytics?.track('button_clicked', {
    buttonName: 'Kreditvergleich starten',
    buttonLocation: currentPageLocation?.href,
    ...(amount && { amount }),
    ...(purpose && { purpose }),
    ...(term && { term }),
    ...(initialPayment && { initialPayment }),
    ...(vehiclePrice && { vehiclePrice: vehiclePrice.toString() }),
    ...getGenericTrackingData(),
  })
}

export const trackBanklistCTAClick = (data: any) => {
  const { amount, purpose, term, productId, pageUrl } = data

  window?.analytics?.track('form_submission-stage_banklist_form', {
    category: 'form_submission',
    label: 'submit',
    formName: 'stage_banklist_form',
    formAction: 'submit',
    formLocation: pageUrl,
    ...(amount && { amount }),
    ...(purpose && { purpose }),
    ...(term && { term }),
    ...(productId && { productId }),
    ...getGenericTrackingData(),
  })
}
